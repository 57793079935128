import { AllLocales } from 'next-intl/dist/types/src/shared/types';
import { getPathname } from '@/lib/navigation';


export const locales = ['en', 'nl'] as const;
export type Locales = "en" | "nl";
export const localePrefix = 'as-needed';
// export const localePrefix = 'always';
export const defaultLocale = 'nl';

export type ExtendedPathnames<Locales extends AllLocales> = Record<string, {
    [Key in Locales[number]]: string;
} & {
    name: string;
    combinedName?: string;
    type?: string;
    option?: string;
    location?: string;
    showInMenu?: boolean;
    showInSiteMap?: boolean;
    includeInMenu?: boolean;
    inflected?: boolean;
    parentKey?: string;
    breadcrumbParentKey?: string;
}>;

export type ExtendedPathname<Locales extends AllLocales> = {
    [Key in Locales[number]]: string;
} & {
    name: string;
    combinedName?: string;
    type?: string;
    option?: string;
    location?: string;
    showInMenu?: boolean;
    showInSiteMap?: boolean;
    includeInMenu?: boolean;
    parentKey?: string;
    breadcrumbParentKey?: string;
};

export const route = (href: string, locale: Locales, suffix = '', debug = false) => {
    if (debug) {
        console.error('route()', href, locale, suffix);
    }

    const pathName = getPathname({
		locale: locale,
		href: href
    });

    if (debug) {
        console.error('\tpathName', pathName);
    }
    
    const pathNameDivider = '';
    // const pathNameDivider = pathName.endsWith('/') ? '' : '/';

    if (locale === defaultLocale) {
        return `${pathName}${pathNameDivider}${suffix}`;
    } else {
        return `/${locale}${pathName}${pathNameDivider}${suffix}`;
    }
}