import React, { useEffect, useState } from 'react';

import { BreadcrumbItems, ComponentProps } from '@/lib/types/shared/page.types';
import { DefaultPageProps } from '@/lib/types/shared/page.types';
import {  Locales, extendedPathnames, locales, usePathname } from '@/lib/navigation';
import { route } from '@/lib/navigationFunctions';
import { getBreadcrumbArray } from '@/lib/helpers/sharedHelper';
import { useTranslations } from 'next-intl';
import Head from '@/components/shared/Head';

type Props = {
    replaceLastLink?: string | null | undefined;
} & DefaultPageProps & ComponentProps;

export default function Breadcrumb({ replaceLastLink, className = '', params }: Props) {
    const t = useTranslations('shared.pathnames');
    const currentPath = usePathname().replace(/\/$/, '');

    const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItems<typeof locales>>();

    useEffect(() => {
        if (!currentPath) return;

        const pathKey = Object.keys(extendedPathnames).find(key => extendedPathnames[key].en === currentPath || extendedPathnames[key].nl === currentPath);

        const breadcrumbArray = getBreadcrumbArray(pathKey ?? '', extendedPathnames);

        if (replaceLastLink) {
            const lastKey = Object.keys(breadcrumbArray).pop();
            
            if (lastKey) {
                const result = extendedPathnames[replaceLastLink];
                
                if (result) {
                    breadcrumbArray[lastKey] = result;
                } else {
                    console.error(`SEO not optimized. \n\n The path ${replaceLastLink} is not found in extendedPathnames. \n\n The link is now falling back to ${lastKey}.`);
                }
            }
        }

        setBreadcrumb(breadcrumbArray);
    }, []);

    function getBreadcrumbSchema(breadcrumb: BreadcrumbItems<typeof locales>) {
        const jsonObject = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": Object.values(breadcrumb).map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": route(item['en'] as Locales, params.locale)
            }))
        };

        console.error('Breadcrumb scheme:', JSON.stringify(jsonObject));
        

        return JSON.stringify(jsonObject);
    }

    return (
        <div>
            {/* {breadcrumb && <Head tag="script" type='application/ld+json' content={getBreadcrumbSchema(breadcrumb)} /> } */}
            
            {breadcrumb && Object.values(breadcrumb).map((item, index) => (
                <React.Fragment key={index}>
                    <a href={route(item['en'] as Locales, params.locale)} className={`underline ${process.env.NEXT_APP_PERFORMANCE === 'true' ? 'text-white font-semibold' : 'text-secondary-700'} ${className}`}>
                        {item.breadcrumbTranslate === false ? item.name : t(item.name)}
                    </a>

                    {index < Object.values(breadcrumb).length - 1 && (
                        <span className={`mx-1 ${process.env.NEXT_APP_PERFORMANCE === 'true' ? 'text-white font-semibold' : 'text-secondary-700'}`}>{'>'}</span>
                    )}
                </React.Fragment>
            ))}
        </div>
    )
}
