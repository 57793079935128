import { Locales } from "@/lib/navigationFunctions";

import { getTranslations } from "next-intl/server";


export const DEPLOY_URL = ``;

export const untranslatedReviews = [
    {
        id: "gerhard",
        name: "Gerhard te Velde",
        image: "Customers/Reviews/gerhard_rttpn0",
        function: "ceo_ear_ear_podcast_agency",
        className: "rounded-full",
        review: "i_really_enjoyed_working_with_webbeukers_to_get_the_new_site_of_my_podcast_agency_ear_ear_off_the_ground",
    },
    {
        id: "sander",
        name: "Sander van den Brink",
        image: "Customers/Reviews/sander_jtpvx0",
        function: "ceo__brinks_watersport",
        review: "they_created_a_highquality_one_website_with_effective_gadgets",
    },
    {
        id: "rudy",
        name: "Rudy Verbeeck",
        image: "Customers/Reviews/rudy_snzypp",
        function: "ceo__swoove",
        review: "their_involvement_does_not_stop_upon_delivery_they_remain_active"
    },
    {
        id: "arian",
        name: "Arian Hosseini",
        image: "Customers/Reviews/arian_haz9ob",
        function: "ceo__avesta__tax",
        review: "nice_company_with_short_lines_we_were_helped_to_our_satisfaction"
    },
    {
        id: "thomas",
        name: "Thomas Guinee",
        image: "Customers/Reviews/thomas_lep2wh",
        function: "ceo__koning_van_de_jungle",
        review: "they_know_a_lot_about_something_that_goes_beyond_mine_so_they_fill"
    }
];

export const DefaultMetaPrefix = `${process.env.NEXT_APP_TITLE_PREFIX}Webbeukers | `;
export const DefaultMetaOgImages = [
    'https://res.cloudinary.com/dxd5axwvg/image/upload/v1715866300/og-image_mkiyqn.jpg',
    'https://res.cloudinary.com/dxd5axwvg/image/upload/v1715866302/og-image-square_ebk0gx.jpg',
];


export const TeamMembers = [
    {
        name: 'Stephan Striekwold',
        function: 'Oprichter',
        image: 'TeamMembers/stephan_a11ipm'
    },
    {
        name: 'Mikail Dikmen',
        function: 'Account Manager',
        image: 'TeamMembers/null_rnkkvz'
    },
    {
        name: 'Bart Ruitenbeek',
        function: 'Project manager & Low-code developer',
        image: 'TeamMembers/bart_nmggv6'
    },
    {
        name: 'Gerrit van Vliet',
        function: 'Lead & Full stack Developer',
        image: 'TeamMembers/gerrit_pfwha8'
    },
    {
        name: 'Michael Dokter',
        function: 'Architect & Full stack Developer',
        image: 'TeamMembers/michael_bagssa'
    },
    {
        name: 'Martien van Vliet',
        function: 'Full stack Developer',
        image: 'TeamMembers/martien_puqwcy'
    },
    {
        name: 'Thomas van Laar',
        function: 'Support engineer & Low-code developer ',
        image: 'TeamMembers/thomas_nyua7i'
    },
    {
        name: 'Yolanda Moss',
        function: 'UI/UX & Brand Designer',
        image: 'TeamMembers/null_rnkkvz'
    },
    {
        name: 'Thomas Guinee',
        function: 'Copywriter & Marketeer',
        image: 'TeamMembers/null_rnkkvz'
    },
    {
        name: 'Matthijs Duijker',
        function: 'Marketeer & Lead Gen',
        image: 'TeamMembers/null_rnkkvz'
    },
    {
        name: 'Natasja Striekwold',
        function: 'Administratie',
        image: 'TeamMembers/null_rnkkvz'
    },
    {
        name: 'Jij?',
        function: 'Vacature: Verkoop adviseur',
        image: 'TeamMembers/null_rnkkvz'
    },
];

export const getGlobalConstants = async (locale: Locales | string = '') => {
    let t = (variable: string) => {
        return variable;
    }

    if (locale) {
        t = await getTranslations({ locale: locale });
    }

    const reviews = untranslatedReviews.map((review) => {
        return {
            ...review,
            function: t(review.function),
            review: t(review.review)
        }
    });

    return {
        reviews
    };
};
export default getGlobalConstants;